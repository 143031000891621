<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="search-contain">
      <Row>
        <Col :lg="{span: 8, offset: 8}" :md="{span: 12}">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
        </Col>
      </Row>
      <Form :label-width="85" class="mt20">
        <Row :gutter="20">
          <Col :lg="{span: 4, offset: 5}" :md="{span: 12}">
            <Form-item label="促进类型：">
              <Select clearable v-model="searchForm.cjlx" placeholder="请选择">
                  <Option value="问题促进">问题促进</Option>
                  <Option value="预警促进">预警促进</Option>
                  <Option value="预约促进">预约促进</Option>
                  <Option value="数据促进">数据促进</Option>
                  <Option value="生日促进">生日促进</Option>
                  <Option value="其他促进">其他促进</Option>
                </Select>
            </Form-item>
          </Col>
          <Col :lg="{span: 4}" :md="{span: 12}">
            <Form-item label="促进师：">
                <Select
                  clearable
                  filterable
                  placeholder="请选择"
                  v-model="searchForm.staff_id"
                >
                  <Option
                    v-for="item in staffList"
                    :value="item.staff_id"
                    :key="item.staff_id"
                  >{{ item.staff__name }}</Option>
                </Select>
            </Form-item>
          </Col>
          <Col span="6">
              <FormItem label="促进记录">
                <Input v-model="searchForm.cjjl" placeholder="请输入" clearable />
              </FormItem>
            </Col>
          <Col :lg="{span: 2}">
            <Button type="warning" @click="searchSubmit()">搜索</Button>
          </Col>
        </Row>
      </Form>

    </div>

    <!-- <div class="add-box">
      <Row>
        <Col span="2" offset="22">
          <div class="member-oprate">
            <router-link class="new-doc fr" :to="{name: 'ServicesProblem'}">查看问题</router-link>
          </div>
        </Col>
      </Row>
    </div> -->



    <div class="table-box pt50">
      <Table stripe :columns="columns" :data="servicesList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="24">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>

  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import servicesService from '@/services/boostService';
export default {
	data() {
		return {
			servicesList: [],
			pageTotal: 0,
			fpmodal: false,
			branch: [],
			service_type: [],
			staffList: [],
			branchArr: [],
			staffArr: [],
			searchForm: {
				page: 1,
				size: 10,
				start: '',
				end: '',
				member_id: this.$route.params.member_id,
				service_branch: '',
				staff_id: '',
				cjlx: '',
				cjjl: '',
			},
			del: {
				services_ids: '',
			},
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '促进时间', key: 'sj', align: 'center' },
				{ title: '促进师', key: 'cjs', align: 'center' },
				{ title: '促进类别', key: 'cjlx', align: 'center' },
				{ title: '促进内容', key: 'cjnr', align: 'center' },
				{ title: '处理记录', key: 'cljl', align: 'center' },
			],
			power: [],
		};
	},
	watch: {
		// 如果 branchArr 发生改变，这个函数就会运行
		branchArr() {
			this.searchForm.service_branch = this.branchArr[1];
		},
		staffArr() {
			this.searchForm.staff = this.staffArr[1];
		},
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	created() {
		this.getServicesList();
		this.power = this.userInfo.power;
		this.getStaffs();
	},
	methods: {
		getStaffs() {
			servicesService.promotionStaffs().then((data) => {
				this.staffList = data;
			});
		},
		getServicesList() {
			servicesService.promotionRecords(this.searchForm).then((data) => {
				this.servicesList = data.list;
				this.pageTotal = data.row_size;
			});
		},
		searchSubmit() {
			this.searchForm.page = 1;
			this.getServicesList();
		},
		handleChangeTime(value) {
			this.searchForm.page = 1;
			this.searchForm.start = value[0];
			this.searchForm.end = value[1];
			this.getServicesList();
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getServicesList();
		},
		selectOne(selection) {
			let arrId = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].id);
			}
			this.del.services_ids = arrId.join(',');
		},
		delPlan() {
			if (!this.del.services_ids) {
				this.$Message.warning('请先选择要删除的记录');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选服务记录吗',
					onOk: () => {
						this.okdel();
					},
					// onCancel: () => {
					//   this.del.services_ids = ''
					// }
				});
			}
		},
		del_one_Plan(index) {
			let param = this.servicesList[index].id;
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除该记录吗',
				onOk: () => {
					servicesService.deleteServices({ services_ids: JSON.stringify(param) }).then(() => {
						// this.searchForm.page = 1
						this.getServicesList();
						this.$Message.warning('删除成功');
					});
				},
			});
		},
		okdel() {
			servicesService.deleteServices(this.del).then(() => {
				// this.searchForm.page = 1
				this.getServicesList();
				this.del.services_ids = '';
			});
		},
	},
};
</script>
<style lang="css" scoped>
.mt20 {margin-top: 20px;}
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}
</style>
